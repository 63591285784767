/**
 * Transform html string containing card links to hash links
 * @param {string} cardPageURL
 * @returns {string}
 */
export function transformHtmlCardLinksToHashLinks(html) {
  const blogCardLinkPattern = /(\/)([A-Za-z]crd[0-9]+)\??(#liveBlogHeader)?" target="_blank"/g;
  const modifiedHtml = html.replace(
    blogCardLinkPattern,
    '#$2"',
  );

  return modifiedHtml;
}
