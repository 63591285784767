import { isFullWidth } from './isFullWidth';

/**
 *
 * @param {[]} bodyArray
 * @returns
 */
export function splitIntoSections(bodyArray) {
  let wasFW = null;

  return bodyArray.reduce((sections, item) => {
    if (!item) return sections;

    const isFW = isFullWidth(item);
    if (
      // if `wasFW` is null, it's the first section
      wasFW !== null
      && ((isFW && !wasFW) || (!isFW && wasFW))
    ) {
      // Add a new section if:
      // - item is full width and previous item was not, or
      // - item is not full width and previous item was.
      sections.push([item]);
    } else {
      // Combine full width and not full width items separately.
      const lastI = sections.length - 1;
      sections[lastI].push(item);
    }

    // Remember
    wasFW = isFW;

    return sections;
  }, [[]]); // set up with the first section (subarray) already in place
}
