import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  shouldRenderEcommerceRecommendations,
  shouldShowAd,
} from 'lib/article';
import { ArticleContext, VerticalContext } from 'lib/ContextTypes';
import { verticalSlugMap } from 'lib/vertical';

import MidresponsiveAd from 'components/Ad/Midresponsive';
import Recommended from 'components/Recommended';

const {
  news: NEWS,
  today: TODAY,
} = verticalSlugMap;

export const BodyBottomRecommended = ({
  block,
  gridBodyClasses,
  shouldRenderTodayGiftGuide,
  showTaboola,
}) => {
  // Using method instead of imported function for test mocks
  const article = React.useContext(ArticleContext);
  const vertical = React.useContext(VerticalContext);

  if (!article || !vertical) {
    return null;
  }

  const { url } = article;
  const renderEcommerceWidget = shouldRenderEcommerceRecommendations(article, vertical);
  const gridBodyDnLClasses = classNames(
    gridBodyClasses,
    'dn-l',
  );

  const gridBodyBotRailRecommendedClasses = classNames(
    gridBodyClasses,
    block,
    `${block}__bottom-rail--recommended`,
  );

  const isAnyAdditionalLabelOnTheShow = (
    article?.taxonomy?.additionalTerms ?? []
  ).find((label) => label?.name?.toLowerCase() === 'on the show');
  const isPrimaryLabelOnTheShow = (article?.taxonomy?.primaryLabel?.name ?? '').toLowerCase() === 'on the show';
  const hasOnTheShowLabel = isAnyAdditionalLabelOnTheShow || isPrimaryLabelOnTheShow;
  const isPrimarySectionShop = (article?.taxonomy?.primarySection?.name ?? '').toLowerCase() === 'shop';
  const shouldRenderShopTheShowRecirc = hasOnTheShowLabel && isPrimarySectionShop;

  return (
    <div
      className="body-bottom-recommended"
      data-test="body-bottom-recommended"
      data-testid="body-bottom-recommended"
    >
      {renderEcommerceWidget === NEWS && (
        <Recommended
          additionalClasses={gridBodyDnLClasses}
          isNBCNewsCommerce
          sticky={false}
          url={url.primary}
          data-test="bottom-recommended-news-ecom"
          pageRegion="article-bottom"
        />
      )}
      {renderEcommerceWidget === TODAY && !shouldRenderShopTheShowRecirc && (
        <Recommended
          additionalClasses={gridBodyBotRailRecommendedClasses}
          isTrending
          sticky={false}
          url={url.primary}
          data-test="bottom-recommended-today-ecom"
          pageRegion="article-bottom"
        />
      )}
      {shouldRenderShopTheShowRecirc && (
        <Recommended
          additionalClasses={gridBodyBotRailRecommendedClasses}
          isTrending
          isShopTheShow
          sticky={false}
          url={url.primary}
          data-test="bottom-recommended-today-ecom"
          pageRegion="article-bottom"
        />
      )}
      {
        shouldShowAd(article)
        && !showTaboola
        && (
          <MidresponsiveAd
            data-test="bottom-recommended-midresponsive"
          />
        )
      }
      {shouldRenderTodayGiftGuide && (
        <Recommended
          additionalClasses={classNames(gridBodyClasses, block)}
          isGiftGuide
          sticky={false}
          url={url.primary}
          data-test="bottom-recommended-giftguide"
          pageRegion="article-bottom"
        />
      )}
    </div>
  );
};

BodyBottomRecommended.propTypes = {
  block: PropTypes.string,
  gridBodyClasses: PropTypes.string,
  shouldRenderTodayGiftGuide: PropTypes.bool,
  showTaboola: PropTypes.bool,
};

BodyBottomRecommended.defaultProps = {
  block: '',
  gridBodyClasses: '',
  shouldRenderTodayGiftGuide: false,
  showTaboola: false,
};
