import { concatMap } from 'lib/arrayUtils';
import buildAffiliateLink from 'lib/buildAffiliateLink';

/**
 *
 * @param {*} params
 * @param {*} params.item
 * @param {boolean} params.isLastItem
 * @param {*} params.prevItem
 * @param {*} params.nextItem
 * @param {boolean} params.ecommerceEnabled
 * @returns
 */
export function checkEcommerce({
  item, isLastItem, prevItem, nextItem, ecommerceEnabled,
}) {
  if (item
    && ecommerceEnabled
    && !isLastItem
    && item.type === 'embeddedImage'
    && prevItem
    && prevItem.type === 'markup'
    && prevItem.element === 'p'
    && prevItem.html.startsWith('<strong>')
    && prevItem.html.indexOf('<a') > -1
  ) {
    return { ...item, shopLink: prevItem };
  }

  if (item
    && ecommerceEnabled
    && !isLastItem
    && item.type === 'markup'
    && item.element === 'p'
    && nextItem
    && nextItem.type === 'embeddedImage'
    && item.html.startsWith('<strong>')
    && item.html.indexOf('<a') > -1
  ) {
    // Legacy Link Replacement. Nasty.
    const matches = item.html.match(/href="([^"]+)"/);
    if (matches?.length) {
      const [, url] = matches;
      const html = item.html.replace(
        /href="([^"]+)"/,
        `href="${buildAffiliateLink({ url })}" target="_blank"`,
      );

      return { ...item, html };
    }
  }

  return item;
}

/**
 *
 * @param {boolean} ecommerceEnabled
 * @returns
 */
export function addEcommerce(ecommerceEnabled) {
  return (bodyArray) => concatMap(bodyArray, (item, i) => {
    const checkedItem = checkEcommerce({
      item,
      isLastItem: i === bodyArray.length - 1,
      prevItem: bodyArray[i - 1],
      nextItem: bodyArray[i + 1],
      ecommerceEnabled,
    });

    return [{ ...checkedItem, ecommerceEnabled }];
  });
}
