const widgetIsFullWidth = (embeddedWidget) => {
  const {
    presentation: {
      size,
    },
  } = embeddedWidget;

  return size === 'large'
    || size === 'fullwidth'
    || size === 'edgetoedge';
};

const imageIsFullWidth = (embeddedImage) => {
  const { presentation: { size }, ecommerceEnabled } = embeddedImage;
  const isNotSmall = size !== 'small' && size !== 'medium';
  if (isNotSmall && !ecommerceEnabled) {
    return true;
  }

  return false;
};

const productIsFullWidth = (embeddedProduct) => {
  const {
    presentation: {
      size,
    },
  } = embeddedProduct;


  return size === 'large';
};

/**
 *
 * @param {{}} item
 * @returns {boolean}
 */
export function isFullWidth(item) {
  const { type } = item;
  switch (type) {
    case 'embeddedWidget':
      return widgetIsFullWidth(item);
    case 'embeddedImage':
      return imageIsFullWidth(item);
    case 'embeddedVideo':
      return false;
    case 'embeddedProduct':
      return productIsFullWidth(item);
    case 'shoppingCartCarousel':
      return true;
    case 'embeddedProductList':
      return true;
    default:
      return false;
  }
}
