import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { article as ArticlePropType } from 'lib/CustomPropTypes';
import { TAX_CONSTANTS, extractTermPaths } from 'lib/taxonomy';

import { RecircEnd as MsnbcDailyRecirc } from 'components/MsnbcDaily/Recirc/End';
import Recommended from 'components/Recommended';

// N.B. - Nebula is setting 'nbcnews/section/msnbc' instead of 'nbcnews/vertical/msnbc'
// as the taxonomy primaryVertical path for MSNBC articles. It appears this is a heavy
// lift to fix on taxonomy side, so we have put in place a temporary patch to allow
// re-circ to be enabled for Nebula published articles. Drupal publishes 'nbcnews/vertical/msnbc'
// so we must check both paths to ensure legacy articles render as expected.
const MSNBC_VERTICAL_TAXONOMY_FALLBACK = 'nbcnews/section/msnbc';

function ArticleFoot({ vertical, article, currentPath }) {
  const {
    taxonomy,
    presentation: { hideRecommendations },
  } = article;

  const termPaths = extractTermPaths(taxonomy);

  const shouldRenderRecircSelect = !hideRecommendations && vertical === 'select';

  const shouldRenderRecircMsnbcDaily = !hideRecommendations
    && (
      termPaths.includes(TAX_CONSTANTS.MSNBC.VERTICAL)
      || termPaths.includes(MSNBC_VERTICAL_TAXONOMY_FALLBACK))
    && termPaths.includes(TAX_CONSTANTS.MSNBC.SECTION_OPINION);

  return (
    <>
      {shouldRenderRecircMsnbcDaily && (
        <MsnbcDailyRecirc pageRegion="article-sidebar" />
      )}
      {shouldRenderRecircSelect && (
        <Recommended
          isRightRail
          currentPath={currentPath}
          isSelect
          additionalClasses={classNames(
            'related--select-foot',
          )}
          url={article.url.primary}
          pageRegion="article-sidebar"
        />
      )}
    </>
  );
}

ArticleFoot.propTypes = {
  article: ArticlePropType.isRequired,
  currentPath: PropTypes.string.isRequired,
  vertical: PropTypes.string.isRequired,
};

export { ArticleFoot };
