/**
 * Look for external links to vendors like Amazon and add rel="sponsored"
 * attribute to the <a> tags.
 * @param {string} trackingId
 * @returns {function}
 */
export function transformSponsoredLinks(trackingId) {
  return (body) => body.map((row) => {
    const html = row.type === 'markup' && row.html;


    if (!html || !trackingId) {
      return row;
    }


    // Look to see if there is tracking Id in the
    // url and then based on that append rel="sponsored"
    const modifiedHtml = html.replace(
      /href="(.+?)"/g,
      (match, url) => {
        if (url.includes(trackingId)) {
          return `href="${url}" rel="sponsored"`;
        }
        return match;
      },
    );

    const modifiedRow = {
      ...row,
      html: modifiedHtml,
    };

    return modifiedRow;
  });
}
