import get from 'lodash.get';
import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import {
  author as AuthorPropType,
  person as PersonPropType,
} from 'lib/CustomPropTypes';
import { reduceCSL } from 'lib/arrayUtils';

const block = 'expanded-contributors';

export const Contributors = ({ authors, containerClass }) => {
  const { t } = useTranslation();

  // Account for different anatomies of the person object
  const names = authors.map((author) => {
    // Account for different anatomies of the person object
    const person = get(author, 'person', author);
    if (!person) {
      return null;
    }
    return person.name;
  }).reduce(reduceCSL, []);

  return (
    <>
      <Head>
        {names.map((name, i) => (
          <meta
            name={`branch:deeplink:contributorName${i + 1}`}
            content={name}
          />
        ))}
      </Head>

      <div className={classNames(block, containerClass)}>
        {names}
        {' '}
        {t('contributed')}
        .
      </div>
    </>
  );
};

Contributors.propTypes = {
  authors: PropTypes.arrayOf(
    AuthorPropType || PersonPropType,
  ),
  containerClass: PropTypes.string,
};

Contributors.defaultProps = {
  authors: [],
  containerClass: '',
};
