/**
 * Looks for jump links that weren't correctly formatted in the CMS (e.g
 * "#My jump link") and formats them as: "#anchor-Myjumplink"
 */
export function transformJumpLinks(body = []) {
  return body.map((row) => {
    const html = row.type === 'markup' && row.html;

    if (!html) {
      return row;
    }

    const modifiedHtml = html.replace(/href="(.+?)"/g, (match, url) => {
      const hashIndex = url.indexOf('#');
      const anchor = url.substring(hashIndex + 1);

      if (hashIndex !== 0 || anchor.startsWith('anchor-')) {
        return match;
      }

      const formattedAnchor = `anchor-${anchor.replace(/[^A-Za-z0-9]/g, '')}`;
      return `href="${`${url.substring(0, hashIndex)}#${formattedAnchor}`}"`;
    });

    const modifiedRow = {
      ...row,
      html: modifiedHtml,
    };

    return modifiedRow;
  });
}
