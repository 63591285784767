export const listenToScroll = ({ isScrolledPastFirstWindow }, setState) => {
  const updatedIsScrolledPastFirstWindow = typeof window !== 'undefined'
      && window.pageYOffset > window.innerHeight;

  if (updatedIsScrolledPastFirstWindow !== isScrolledPastFirstWindow) {
    setState(updatedIsScrolledPastFirstWindow);
  }
};

export const monitorArticleView = (article, setState) => (id) => {
  if (id === article.id) {
    setState(true);
  }
};
