import { transformHtmlCardLinksToHashLinks } from 'lib/transformHtmlCardLinksToHashLinks';

/**
 * Transform links in an article's body elements
 * @param {boolean} isLiveBlog
 * @returns {function}
 */
export function transformLegacyLinks(body) {
  return body.map((row) => {
    const html = row.type === 'markup' && row.html;

    if (!html) {
      return row;
    }

    const modifiedRow = {
      ...row,
      html: transformHtmlCardLinksToHashLinks(row.html),
    };

    return modifiedRow;
  });
}
