const VALID_ENDMARK_CONFIG = {
  markup: {
    p: true,
    ul: true,
  },
  embeddedWidget: true,
  embeddedVideo: true,
  embeddedProduct: true,
  embeddedCuratedList: true,
};

/**
 * Parsing function to be used in the pipe of getSections. An endmark is whatever logo NBC wants
 * to see at the end of an article. Since later pipes of getSections() insert additional non-article
 * content to the body, we require this function to ensure the endmark gets placed in the right
 * position.
 *
 * @param {articleItems[]} articleContent Previously parsed article items array from getSections()
 */
export const insertEndmark = (articleContent = []) => {
  for (let x = articleContent.length - 1; x >= 0; x -= 1) {
    const item = articleContent[x] || {};
    const validEndmarkType = VALID_ENDMARK_CONFIG[item.type];

    if (validEndmarkType && (validEndmarkType === true || validEndmarkType[item.element])) {
      item.insertEndmark = true;
      break;
    }
  }

  return articleContent;
};
