import pipe from 'lib/pipe';

// Sort content slots into groups of siblings
const reduceConsecutive = (slots, index) => {
  if (!slots.length) {
    return [[index]];
  }
  const sibs = slots[slots.length - 1];
  if (index === sibs[sibs.length - 1] + 1) {
    sibs.push(index);
    return slots;
  }
  return [...slots, [index]];
};

const isContent = (item, includeAds = false) => {
  if (item.element && ['p'].indexOf(item.element) > -1) {
    return true;
  }
  if (includeAds && item.type && item.type === 'advertisement') {
    return true;
  }
  if (item.type && item.type === 'recommended') {
    return true;
  }
  return false;
};

// Reduce body into slots of adjacent content item
function findContentSlots(body, includeAds = false) {
  const reducer = (adSlots, item, index) => {
    if (index >= body.length - 1) {
      return adSlots;
    }
    if (isContent(item, includeAds) && isContent(body[index + 1], includeAds)) {
      return [...adSlots, index];
    }
    return adSlots;
  };

  return body.reduce(reducer, []);
}

// Given an index (insertion point candidate),
// get information about it's siblings
function findContentCluster(insertionIndex, reduced) {
  let positionInCluster;
  const reducedIndex = reduced.findIndex(
    (r) => {
      const idx = r.indexOf(insertionIndex);
      if (idx >= 0) {
        positionInCluster = idx;
      }
      return (idx >= 0);
    },
  );

  return { reducedIndex, positionInCluster };
}

// Voodoo logic attempting to find best safe zone to insert content
// approximately from 1/2 + of the article content
function findInsertionIndex(slots, reduced) {
  const count = slots.length;
  if (count < 0) {
    return -1;
  }

  if (count <= 2) {
    return 0;
  }

  // Consider a spot aproximately in a middle of an article
  const slotIndex = Math.ceil(count / 2);
  const index = slots[slotIndex];

  // Is this a good place to insert recommendation module?
  // (checking if it's inside long content cluster and not at the very end of it)
  const { reducedIndex, positionInCluster } = findContentCluster(index, reduced);
  const clusterSize = reduced[reducedIndex].length;

  if (clusterSize >= 3 && positionInCluster < clusterSize - 1) {
    return index;
  }

  // Find next availble long (at least three consecutive items) content cluster
  // if found, insert at the beggining
  const reducedTail = reduced.slice(reducedIndex);
  if (reducedTail) {
    const reducedTailIndex = reducedTail.findIndex((rt) => rt.length >= 3);
    if (reducedTailIndex >= 0) {
      return reducedTail[reducedTailIndex][0];
    }
  }

  // Otherwise default to second to the last paragraph
  return slots[count - 2];
}

// Adjusts the placement to sit 3 paragraphs below an image, and at least 1
// paragraph after.
function findInsertionIndexAfterImage(body, slots, reduced, index = 0) {
  for (let i = index; i < body.length; i += 1) {
    if (body[i].type === 'embeddedImage') {
      const insertPosition = i + 3;

      if (
        body[insertPosition]?.type === 'embeddedImage'
        || body[insertPosition + 1]?.type === 'embeddedImage'
      ) {
        return findInsertionIndexAfterImage(body, slots, reduced, insertPosition);
      }
      return (insertPosition < body.length) ? insertPosition : -1;
    }
  }

  return findInsertionIndex(slots, reduced);
}

/**
 * Insert recommendation component at the most suitable position
 * @param {Article} content
 * @param {boolean} mobile
 * @returns
 */
function insertRecoComponent(content, mobile = true) {
  return (body) => {
    const slots = findContentSlots(body, !mobile);
    const reduced = slots.reduce(reduceConsecutive, []);
    const index = findInsertionIndexAfterImage(body, slots, reduced);

    if (index >= 0) {
      body.splice(index + 1, 0, { content, mobile, type: 'recommended' });
    }

    return body;
  };
}

/**
 *
 * @param {Article} content
 * @param {boolean} disableReco
 * @returns
 */
function insertRecommendations(content, disableReco) {
  return (body) => {
    const noBodyArticle = body.length === 1 && body[0].type && body[0].type === 'embeddedWidget';

    return (noBodyArticle || disableReco)
      ? body
      : pipe(
        // Mobile (account for ad placements)
        insertRecoComponent(content, true),
        // Desktop (ignore mobile ads)
        insertRecoComponent(content, false),
      )(body);
  };
}

export { insertRecommendations };
