import { isProductSpecsHeader } from 'lib/LDJson/buildListicleProduct';

/**
 * Remove markup tags of type h4 with text = 'Product Specs'
 * These are just placeholders editors add to mark parsable content for LD+JSON
 * @param {array} body
 * @returns {array}
 */
export function removeProductSpecMarkers(body) {
  return body.filter(
    (row) => !isProductSpecsHeader(row),
  );
}
