import React from 'react';
import PropTypes from 'prop-types';

import { isShellArticle, shouldShowTaboola } from 'lib/article';
import { article as ArticlePropType } from 'lib/CustomPropTypes';

import Taboola from 'components/Taboola';

export const BodyTaboola = ({ isRail, article, showTaboola }) => {
  if (isShellArticle(article)) {
    return null;
  }
  const { id, url, ecommerceEnabled } = article;

  const className = isRail && ecommerceEnabled ? 'dn-l' : null;

  if (!shouldShowTaboola(article, showTaboola)) {
    return null;
  }

  return (
    <Taboola
      id={id}
      canonicalUrl={url.canonical || url.primary}
      isRail={isRail}
      className={className}
      ecommerceEnabled={ecommerceEnabled}
    />
  );
};


BodyTaboola.propTypes = {
  isRail: PropTypes.bool,
  article: ArticlePropType.isRequired,
  showTaboola: PropTypes.bool,
};

BodyTaboola.defaultProps = {
  isRail: false,
  showTaboola: false,
};
