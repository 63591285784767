import get from 'lodash.get';
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  author as AuthorPropType,
  person as PersonPropType,
} from 'lib/CustomPropTypes';

import {
  BylineBio,
  BylineName,
  BylineSocial,
  BylineThumbnail,
} from 'components/Byline';
import { getDataActivityMapID } from 'lib/articleUtils';

/* Important: Making changes to this file could have an impact on shorthand articles */

const block = 'expanded-byline';

const dataActivityMapID = getDataActivityMapID({
  pageRegion: 'article-bottom',
  componentName: 'expanded-byline',
});

export const ExpandedByline = ({ author, containerClass, position }) => {
  // Account for different anatomies of the person object
  const person = get(author, 'person', author);
  if (!person) {
    return null;
  }
  return (
    <div
      className={classNames(block, containerClass)}
      data-activity-map={dataActivityMapID}
    >
      <BylineThumbnail
        person={person}
        additionalClasses={`${block}__thumbnail`}
        additionalClassesNoImage={`${block}__thumbnail`}
        position={position}
      />
      <BylineName
        person={person}
        additionalClasses={`${block}__name`}
        position={position}
      />
      <BylineSocial
        person={person}
        additionalClasses={`${block}__social`}
      />
      <BylineBio
        person={person}
        additionalClasses={`${block}__bio`}
      />
    </div>
  );
};

ExpandedByline.propTypes = {
  author: AuthorPropType || PersonPropType,
  containerClass: PropTypes.string,
  position: PropTypes.number.isRequired,
};

ExpandedByline.defaultProps = {
  author: {},
  containerClass: '',
};
