import React from 'react';
import styles from './styles.module.scss';

function Head() {
  return (
    <div className={styles.head}>
      <h3 className={styles.title}>
        MSNBC Daily
      </h3>
      <p className={styles.description}>
        Catch up on more stories from MSNBC Daily featuring
        the top voices and opinions driving today&#39;s conversation
      </p>
    </div>
  );
}

export { Head };
