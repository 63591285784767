const isContent = (item) => (item.element && ['p', 'ul', 'ol'].indexOf(item.element) > -1) || false;

// Return an array of suitable slots for ad placements
// A suitable slot is defined by the presence of two consecutive "content items"
// Each ad slot has references to its index in the body, and its index in terms of "content items"
function findAdSlots(body) {
  let contentCount = 0;

  const reducer = (adSlots, item, index) => {
    if (index >= body.length - 1) {
      return adSlots;
    }

    if (isContent(item)) {
      contentCount += 1;
    }

    if (isContent(item) && isContent(body[index + 1])) {
      return [...adSlots, { index, contentCount }];
    }

    return adSlots;
  };

  return body.reduce(reducer, []);
}

function insertAdvertisements(body) {
  const ad = { type: 'advertisement' };
  const adSlots = findAdSlots(body);
  const firstAd = 2;
  const secondAdSlotPosition = 4;
  const subsequent = 6;
  let spacing = firstAd;
  let lastIndex = 0;
  // Entries in adPositions represent the number of paragraphs
  // (or other "content items") after which an ad should appear
  const adPositions = adSlots.filter((e) => {
    let addFirstAd = false;
    let addSubsAds = false;
    let addAd = false;

    if (spacing === firstAd && e.contentCount >= firstAd) {
      // First Placement
      addFirstAd = true;
      addAd = true;
    } else if (e.contentCount >= spacing && (lastIndex + secondAdSlotPosition) <= e.index) {
      // Second Placement.
      addSubsAds = true;
      addAd = true;
    } else if (e.contentCount >= spacing && (lastIndex + subsequent) <= e.index) {
      // Subsequent placement is appropriately spaced from previous.
      addSubsAds = true;
      addAd = true;
    }
    if (addFirstAd) {
      lastIndex = e.index;
      spacing += secondAdSlotPosition;
    }
    if (addSubsAds) {
      lastIndex = e.index;
      spacing += subsequent;
    }
    return addAd;
  }).map((e) => e.index);

  // Splice ads into body in reverse to avoid need to increment indexes.
  adPositions.reverse().forEach((position) => {
    body.splice(position + 1, 0, ad);
  });

  return body;
}

function insertTaboolaElement(body) {
  const taboolaReadMoreBelow = { type: 'taboolaReadMoreBelow' };
  const indexOfFirstAd = body.findIndex((item) => item.type === 'advertisement');

  if (indexOfFirstAd > -1) {
    body.splice(indexOfFirstAd + 1, 0, taboolaReadMoreBelow);
  }

  return body;
}

/**
 *
 * @param {boolean} nativeAd
 * @param {boolean} adsEnabled
 * @returns {function}
 */
export function insertAdsIfNotNativeAd(
  nativeAd,
  adsEnabled,
) {
  return (body) => (
    (nativeAd || !adsEnabled)
      ? body
      : insertTaboolaElement(insertAdvertisements(body))
  );
}
