export const TYPE = 'shoppingCartCarousel';

export function insertShoppingCart({
  isShoppable,
  article,
  vertical,
}) {
  if (!isShoppable) {
    return (body) => body;
  }

  return (body) => {
    const slicePoint = body.findIndex((item) => item.type === 'embeddedProduct') - 1;
    return [
      ...body.slice(0, slicePoint),
      {
        type: TYPE,
        article,
        vertical,
        presentation: { size: 'edgetoedge' },
      },
      ...body.slice(slicePoint),
    ];
  };
}
