/**
 * Normalizer function to aid in extracting the sections from an article. External links are missing
 * the description and external link. This function returns a more consistent Article Body array.
 */
export const getNormalizedArticleBody = (article) => {
  const { subType = '', body = [] } = article;

  if (subType === 'externalLink') {
    const { description, url } = article;
    // Store the body data in format getSections expects.
    return [
      // Description as first paragraph.
      {
        type: 'markup',
        element: 'p',
        html: description.primary || description.social,
      },
      // The external link.
      {
        type: subType,
        url: url.primary,
      },
    ];
  }

  // Default
  return body;
};
