export function populateQuickTakesEmbed() {
  return (body) => body.map((item) => {
    if (item.type !== 'embeddedWidget') {
      return item;
    }

    const {
      widget: {
        name,
        properties: {
          embed: {
            products: allProducts = [],
            type: embedType,
          } = {},
        },
      },
    } = item;
    const products = allProducts.filter((p) => p.hidden !== true);

    const isQuickTakeEmbed = name === 'CUSTOM_EMBED' && embedType === 'COMMERCE_QUICK_TAKES';
    if (!isQuickTakeEmbed) {
      return item;
    }

    const productMap = body
      .filter((i) => i.type === 'embeddedProduct')
      .reduce((obj, i) => ({
        ...obj,
        [i.product.id]: i.product,
      }), {});

    const modifiedItem = {
      ...item,
      widget: {
        ...item.widget,
        properties: {
          ...item.widget.properties,
          embed: {
            ...item.widget.properties.embed,
            products: products.map((p) => ({
              ...p,
              ...productMap[p.id],
            })),
          },
        },
      },
    };

    return modifiedItem;
  });
}
