export const initializeObservableCallback = (component, name) => (header = []) => {
  const hfs = header[0];
  const globalIsStashed = hfs.target?.classList
    && hfs.target.classList.contains('stash-global');
  const isBumped = !globalIsStashed;

  if (isBumped !== component.state[name]) {
    component.setState({ [name]: isBumped });
  }
};

export const listenForHFSBump = (component, name) => {
  if (typeof window !== 'undefined') {
    const hfsNavBar = (document.querySelector('#hfs-header > nav'));
    if (hfsNavBar) {
      const callback = initializeObservableCallback(component, name);
      const observer = new MutationObserver(callback);
      observer.observe(hfsNavBar, { attributes: true });
      return observer;
    }
  }
  return null;
};

export const disconnectHFSBump = (observer) => {
  if (observer && typeof observer.disconnect === 'function') {
    observer.disconnect();
  }
};

export default {
  listenForHFSBump,
  disconnectHFSBump,
  initializeObservableCallback,
};
