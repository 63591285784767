import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { ARTICLE_INLINE_NEWSLETTER, MID_ARTICLE_NEWSLETTER } from 'lib/brandFeatures';

/**
 *
 * @param {Article} article
 * @param {VerticalType} vertical
 * @param {boolean} isLiveBlog
 * @param {boolean} breakingNews
 * @returns
 */
export const insertInlineNewsletterModule = (
  article,
  vertical,
  isLiveBlog,
  breakingNews,
) => (body) => {
  if (
    !getFeatureConfigForBrand(ARTICLE_INLINE_NEWSLETTER, vertical)
    || isLiveBlog
    || breakingNews
  ) {
    return body;
  }
  const changedBody = [...body];
  const totalParagraphs = changedBody.filter((item) => item && item.type === 'markup').length;
  const finalIndex = changedBody.length - 1;
  const inlineModule = {
    type: 'inlineNewsletter',
    props: { article, vertical },
  };

  // not all brands get mid-article feature
  if (getFeatureConfigForBrand(MID_ARTICLE_NEWSLETTER, vertical) && totalParagraphs >= 8) {
    let insertIndex = Math.floor(changedBody.length / 2);
    /**
     * function to ascertatin whether insert point is next to a small module
     * when next to small modules the signup's styling can bleed into said module
     * not only is it ugly to look at, it breaks the flow of the page
     * we move it down 2 indices to allow for page flow to be as uninterrupted as possible
     * @param {number} arrayIndex insert index we check against for distance from nearest small element
     * @returns {boolean}
     */
    const getIsNearSmallModule = (arrayIndex) => (
      changedBody[arrayIndex].presentation?.size === 'small'
      || changedBody[arrayIndex - 1].presentation?.size === 'small'
      || changedBody[arrayIndex - 2].presentation?.size === 'small'
    );
    /**
     * checks if our insert index is directly preceded by an ad
     * @param {number} arrayIndex - the insert index to be checked against
     * @returns {boolean}
     */
    const getIsNexToAd = (arrayIndex) => changedBody[arrayIndex - 1].type === 'advertisement';


    /**
     * if it's next to a small it needs to move down
     * if it's not next to a small we need to make sure it's also not next to an ad
     * this is because ads are not rendered in page flow like other elements
     * as such we cannot treat ads as normal content, as by doing so we can still end up near a small
     * @param {number} arrayIndex - the insert index to be checked against
     * @returns {boolean}
     */
    const getNeedsToMoveDown = (arrayIndex) => (
      getIsNearSmallModule(arrayIndex)
        ? true
        : getIsNexToAd(arrayIndex)
    );

    let needsToMoveDown = getNeedsToMoveDown(insertIndex);

    while (needsToMoveDown) {
      // to avoid infinite loop possibilities, if we get to the end of the array we'll attach there as normal.
      if (insertIndex === finalIndex) {
        needsToMoveDown = false;
      } else {
        insertIndex += 1;
        needsToMoveDown = getNeedsToMoveDown(insertIndex);
      }
    }
    changedBody.splice(insertIndex, 0, inlineModule);
  } else {
    changedBody.push(inlineModule);
  }

  return changedBody;
};
