import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
  isShellArticle,
  shouldRenderEcommerceRecommendations,
  shouldShowAd,
} from 'lib/article';
import Breakpoints from 'lib/Breakpoints';
import { ArticleContext, VerticalContext } from 'lib/ContextTypes';
import {
  articleSection as ArticleSectionPropType,
} from 'lib/CustomPropTypes';
import { verticalSlugMap } from 'lib/vertical';

import Ad from 'components/Ad';
import Recommended from 'components/Recommended';

import { BodyTaboola } from './BodyTaboola';

const {
  news: NEWS,
  today: TODAY,
  select: SELECT,
} = verticalSlugMap;

function shouldShowRecircSelect(vertical, ad, sectionIndex) {
  const sectionElement = typeof document !== 'undefined' && document.querySelectorAll('.article-body__section')[sectionIndex + 1];
  const isContainerTallEnough = sectionElement && sectionElement.querySelector('.article-body').offsetHeight >= 600;
  return vertical === SELECT && ad.display && isContainerTallEnough;
}

export const BodyRightRail = ({
  block,
  section,
  sectionIndex,
  articleContainsLargeProduct,
  shouldRenderRightRailTaboola,
  getRightRailAdConfig,
  currentPath,
  boxflexAdRenderCheck,
  boxflexRendered,
  isScrollingUp,
  showTaboola,
}) => {
  const article = useContext(ArticleContext);
  const vertical = useContext(VerticalContext);
  if (!article || !vertical) {
    return null;
  }

  const { ad = {} } = section;
  const { url, nativeAd, ecommerceEnabled } = article;

  const isFirstSection = sectionIndex === 0;
  const isThirdSection = sectionIndex === 2;

  const isSectionForTodayRecommendationWidget = articleContainsLargeProduct
    ? isThirdSection
    : isFirstSection;

  const renderECommerceWidget = shouldRenderEcommerceRecommendations(article, vertical);
  const displayTodayEcommerceWidget = isSectionForTodayRecommendationWidget
      && renderECommerceWidget === TODAY;

  const adConfig = typeof getRightRailAdConfig === 'function'
    ? getRightRailAdConfig({
      section,
      nativeAd,
      sectionIndex,
      isFirstSection,
      isThirdSection,
    })
    : ad;
  const shouldRenderAd = adConfig.display && shouldShowAd(article) && !ecommerceEnabled;

  const shouldRenderRecircSelect = shouldShowRecircSelect(vertical, ad, sectionIndex);

  const shouldRenderTaboola = typeof shouldRenderRightRailTaboola === 'function'
    ? shouldRenderRightRailTaboola({
      section,
      nativeAd,
      sectionIndex,
      isFirstSection,
      isThirdSection,
    })
    : !isShellArticle(article) && section.taboola && !Breakpoints.isS();

  const isAnyAdditionalLabelOnTheShow = (
    article?.taxonomy?.additionalTerms ?? []
  ).find((label) => label?.name?.toLowerCase() === 'on the show');
  const isPrimaryLabelOnTheShow = (article?.taxonomy?.primaryLabel?.name ?? '').toLowerCase() === 'on the show';
  const hasOnTheShowLabel = isAnyAdditionalLabelOnTheShow || isPrimaryLabelOnTheShow;
  const isPrimarySectionShop = (article?.taxonomy?.primarySection?.name ?? '').toLowerCase() === 'shop';
  const shouldRenderShopTheShowRecirc = hasOnTheShowLabel && isPrimarySectionShop;

  if (shouldRenderTaboola || section.newsletter || shouldRenderAd
    || displayTodayEcommerceWidget || shouldRenderRecircSelect) {
    return (
      <div
        data-test="right-rail-container"
        data-testid="right-rail-container"
        className={classNames(
          `${block}__right-rail`,
          'layout-grid-item',
          'grid-col-4_5-l',
          'grid-col-3_5-xl',
          'grid-col-push-half-xl',
          {
            [`${block}__right-rail-ecom di-l dn`]: displayTodayEcommerceWidget,
            HFSBumped: isScrollingUp,
          },
        )}
      >
        {shouldRenderShopTheShowRecirc && (
          <Recommended
            isShopTheShow
            isTrending
            url={url.primary}
            additionalClasses={classNames(
              `${block}__right-rail--recommended`,
              {
                [`${block}__non-sticky`]: boxflexRendered,
                pb1: boxflexRendered,
              },
            )}
            isRightRail
            data-test="right-rail-today-ecom"
            pageRegion="article-sidebar"
          />
        )}
        {shouldRenderRecircSelect && (
          <Recommended
            isRightRail
            currentPath={currentPath}
            isSelect
            url={url.primary}
            additionalClasses={classNames(
              `${block}__right-rail--recommended`,
              {
                [`${block}__non-sticky`]: boxflexRendered,
                pb1: boxflexRendered,
              },
            )}
            data-test="right-rail-select"
            pageRegion="article-sidebar"
          />
        )}
        {shouldRenderTaboola && (
          <BodyTaboola
            isRail
            article={article}
            showTaboola={showTaboola}
            data-test="right-rail-taboola"
          />
        )}
        {renderECommerceWidget === NEWS && (
          <Recommended
            isNBCNewsCommerce
            url={url.primary}
            additionalClasses={classNames(
              `${block}__right-rail--recommended`,
              {
                [`${block}__non-sticky`]: boxflexRendered,
                pb1: boxflexRendered,
              },
            )}
            isRightRail
            data-test="right-rail-news-ecom"
            pageRegion="article-sidebar"
          />
        )}
        {renderECommerceWidget === TODAY && !shouldRenderShopTheShowRecirc && (
          <Recommended
            isTrending
            url={url.primary}
            additionalClasses={classNames(
              `${block}__right-rail--recommended`,
              {
                [`${block}__non-sticky`]: boxflexRendered,
                pb1: boxflexRendered,
              },
            )}
            isRightRail
            data-test="right-rail-today-ecom"
            pageRegion="article-sidebar"
          />
        )}
        {shouldRenderAd && (
          <Ad
            slot={(isFirstSection && vertical === NEWS) ? 'boxrail' : adConfig.slot}
            enumerator={adConfig.index}
            adClass={`${block}__right-rail--ad`}
            offset={10}
            handler={boxflexAdRenderCheck}
            data-test="right-rail-ad"
          />
        )}
      </div>
    );
  }

  return null;
};

BodyRightRail.propTypes = {
  block: PropTypes.string,
  section: ArticleSectionPropType.isRequired,
  sectionIndex: PropTypes.number.isRequired,
  articleContainsLargeProduct: PropTypes.bool,
  shouldRenderRightRailTaboola: PropTypes.func,
  getRightRailAdConfig: PropTypes.func,
  currentPath: PropTypes.string,
  boxflexAdRenderCheck: PropTypes.func,
  boxflexRendered: PropTypes.bool,
  isScrollingUp: PropTypes.bool,
  showTaboola: PropTypes.bool,
};

BodyRightRail.defaultProps = {
  block: '',
  articleContainsLargeProduct: false,
  shouldRenderRightRailTaboola: null,
  getRightRailAdConfig: null,
  currentPath: '',
  boxflexAdRenderCheck: null,
  boxflexRendered: false,
  isScrollingUp: false,
  showTaboola: false,
};
