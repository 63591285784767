import React, { useContext } from 'react';
import get from 'lodash.get';
import classNames from 'classnames';

import { getPackagesByType } from 'lib/curationUtils';
import { ArticleContext } from 'lib/ContextTypes';
import { getUniformTeaseItem } from 'lib/teaseUtils';

import { Border } from 'components/MsnbcDaily/Border';
import { Head } from 'components/MsnbcDaily/Recirc/End/Head';
import { Item } from 'components/MsnbcDaily/Recirc/End/Item';
import { useFetch } from 'components/MsnbcDaily/Recirc/fetch';

import { getDataActivityMapID } from 'lib/articleUtils';
import { passTrackingMSNBCDaily } from '../../utility';

import styles from './styles.module.scss';

const section = null;
const packageType = 'msnbcDailyArticles';


function RecircEnd() {
  const article = useContext(ArticleContext);

  const [response, loading, hasError] = useFetch(
    `curation/slug/msnbc${section ? `/${section}` : ''}`,
  );

  if (loading === true || hasError === true || !response) {
    return null;
  }

  const curation = get(response, ['curation'], {});

  const packages = getPackagesByType(curation, packageType);
  if (!Array.isArray(packages) || !packages.length) {
    return null;
  }

  const daily = packages[0];
  const items = get(daily, 'items', [])
    .filter((item) => item) // filter invalid items
    .slice(0, 6);

  const dataActivityMapID = getDataActivityMapID(
    {
      componentName: 'msnbc-daily-recirc',
      pageRegion: 'article-sidebar',
      componentTitle: 'MSNBC Daily',
    },
  );
  return (
    <div
      className={classNames('layout-grid-container', styles.msnbcDailyRecircEnd)}
      data-activity-map={dataActivityMapID}
    >
      <div className="layout-grid-item grid-col-12">
        <div className="layout-subgrid-container">
          <div className="layout-subgrid-item grid-col-12 grid-col-9-l grid-col-8-xl">
            <Head />
          </div>
        </div>
        <div className={styles.borderTop} />
        <div className={classNames(styles.body, 'layout-subgrid-container')}>
          {items.map((item, i) => {
            const tease = getUniformTeaseItem(item);

            // Append tracking to content and unibrow links
            const trackingType = 'recircArticleBottom';
            const url = passTrackingMSNBCDaily(get(tease, 'url'), trackingType);
            const unibrow = get(tease, 'unibrow');

            return (
              <div
                className={classNames(styles.item, 'layout-subgrid-item grid-col-12 grid-col-4-m')}
                key={item.id}
              >
                <Item
                  active={item.id === article.id}
                  headline={tease.headline}
                  index={i + 1}
                  publicationName={get(tease, ['metadata', 'publicationName'], null)}
                  type={tease.type}
                  unibrow={unibrow}
                  url={url?.url}
                  icid={url?.icid}
                />
              </div>
            );
          })}
        </div>
        <div className={styles.borderBottom}>
          <Border />
        </div>
      </div>
    </div>
  );
}

export { RecircEnd };
